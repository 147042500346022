
import { defineComponent, onMounted, ref, computed } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { SupportEmailRequestDto } from "../../core/types/EmailTypes";

export default defineComponent({
  name: "contact-content-expert",
  components: { Field, Form, ErrorMessage },
  setup() {
    const store = useStore();
    const submitButton = ref<HTMLElement | null>(null);
    const currentUser = store.getters.currentUser;
    const currentClient = computed(() => {
      return store.getters.getCurrentClientInfo;
    });

    const emailRequest = ref<SupportEmailRequestDto>({
      toName: "",
      toEmail: "",
      ccEmail: "",
      clientId: 0,
      fromFirstName: currentUser.firstName,
      fromLastName: currentUser.lastName,
      fromEmail: currentUser.email,
      subject: "",
      message: "",
    });

    const formValidation = Yup.object().shape({
      fromFirstName: Yup.string().required().label("First Name"),
      fromLastName: Yup.string().required().label("Last Name"),
      fromEmail: Yup.string().min(4).required().email().label("Email"),
      subject: Yup.string().required().label("Subject"),
    });

    onMounted(() => {
      setCurrentPageTitle("Contact Content Expert");
      setCurrentPageBreadcrumbs("Contact Content Expert", ["Support"]);
    });

    const onSubmitEmail = () => {
      // Activate indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");
      emailRequest.value.clientId = currentClient.value.client.clientId;
      // Send login request
      store
        .dispatch(Actions.EMAIL_CONTENT_EXPERT, emailRequest.value)
        .then(() => {
          Swal.fire({
            text: "Success. The email has been sent.",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Continue",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          }).then(function () {
            resetForm();
          });
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });

      submitButton.value?.removeAttribute("data-kt-indicator");
    };

    function resetForm() {
      emailRequest.value = {
        toName: "",
        toEmail: "",
        ccEmail: "",
        clientId: 0,
        fromFirstName: currentUser.firstName,
        fromLastName: currentUser.lastName,
        fromEmail: currentUser.email,
        subject: "",
        message: "",
      };
    }

    return {
      currentUser,
      currentClient,
      emailRequest,
      formValidation,
      onSubmitEmail,
      submitButton,
      resetForm,
    };
  },
});
